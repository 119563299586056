import { Theme } from "@emotion/react";
import { Popover, popoverClasses, PopoverOrigin, SxProps } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties, MutableRefObject } from "react";
import { ReactNode } from "react";
import { FunctionComponent, useRef, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    popover: {
      pointerEvents: "none"
    },
    paper: {
        pointerEvents: "auto",
        //@ts-ignore
        ...theme.customProps.popoverPaperStyle
    }
  }));

export interface InteractionChildrenForwardProps {
  ref?: MutableRefObject<any>
  onMouseEnter: ()=>void
  onMouseLeave: ()=>void
}

export interface InteractionPopoverProps {
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  content?: ReactNode
  children?: (childProps: InteractionChildrenForwardProps, openedPopover: boolean)=>ReactNode
  paperStyle?: CSSProperties
  sx?: any
  disableEnforceFocus?: boolean
}

/**
 * 
 * Popover that also opens and stays open on hover.
 * 
 * @returns 
 */
export const InteractionPopover: FunctionComponent<InteractionPopoverProps> = ({children, content, anchorOrigin, transformOrigin, paperStyle, sx, disableEnforceFocus, ...props}) => {

    const [openedPopover, setOpenedPopover] = useState<boolean>(false)
    const popoverAnchor = useRef(null);
  
    const classes = useStyles();
  
    const popoverEnter = () => {
      setOpenedPopover(true);
    }
  
    const popoverLeave = () => {
      setOpenedPopover(false);
    }
  
    const childProps = {
        ref: popoverAnchor,
        'aria-owns': 'mouse-over-popover',
        'aria-haspopup': 'true',
        onMouseEnter: popoverEnter,
        onMouseLeave: popoverLeave,
    };

    if (sx) {
      sx = [{
        pointerEvents: 'none',
        [`& .${popoverClasses.paper}`]: {
          pointerEvents: 'auto',
        }
      },sx]
    }

    return (
      <>
        {children(childProps, openedPopover)}
        <Popover
          id="mouse-over-popover"
          className={sx ? null : classes.popover}
          classes={sx ? null: {
            paper: classes.paper
          }}
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: anchorOrigin?.vertical || "center",
            horizontal: anchorOrigin?.horizontal || "left"
          }}
          transformOrigin={{
            vertical: transformOrigin?.vertical || "center",
            horizontal: transformOrigin?.horizontal || "right"
          }}
          PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave, style: paperStyle }}
          disableRestoreFocus={true}
          disableEnforceFocus={disableEnforceFocus}
          {...props}
          sx={sx}
        >
          {content}
        </Popover>
      </>
    );
  };
  